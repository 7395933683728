import UserStore from "../../Store/UserStore";
import { Client } from "../../API";
import { useNavigate } from "react-router-dom";
import './ClientSelect.css'
import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import MenuComponent from "../../components/ManuComponent/MenuComponent";

function ClientSelect() {

    const navigate = useNavigate();
    const clients = UserStore((state) => state.clients)
    const setClient = UserStore((state) => state.setClient)

    const handleClientSelect = (client: Client) => {
        setClient(client)
        navigate('ClientDetails/' + client.nameID.toLowerCase())
    };

    return (
        <div>
            <MenuComponent />
            <div className='ClientSection'>
                <img className="logo" alt='logo' src={process.env.PUBLIC_URL + '/logo.png'} />
                <div className="ClientCards">
                    {clients.map((client) => (
                        <div key={client.id}>
                            <Card className="Card"
                                onClick={() => handleClientSelect(client)}
                                hoverable
                                cover={<div className='ClientSectionLogoImage'>
                                    <img alt={client.nameID} src={process.env.PUBLIC_URL + '/' + client.nameID.toLowerCase() + '_logo.jpg'} />
                                </div>}>
                                <Meta title={client.name} />
                            </Card>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ClientSelect