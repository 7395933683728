
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Location, Restriction } from '../API'

type StateType = State | Partial<State> | ((state: State) => State | Partial<State>)

type State = {
    locations: Location[]
    currentLocation: Location
    restrictions: Restriction[]
}

type Actions = {
    setLocations: (locations: Location[]) => void
    setLocation: (locations: Location) => void
    setRestrictions: (restrictions: Restriction[]) => void
}
const LocationStore = create<State & Actions, [['zustand/devtools', State & Actions]]>(devtools((set) => ({
    locations: [],
    restrictions: [],
    currentLocation: { id: '', createdAt: '', updatedAt: '', clientID: '', __typename: "Location" },
    setLocations: (locations: Location[]) => set(setLocations(locations), false, "Set locations"),
    setLocation: (location: Location) => set(setLocation(location), false, "Set location"),
    setRestrictions: (restrictions: Restriction[]) => set(setRestrictions(restrictions), false, "Set restriction")
})))

function setLocations(locations: Location[]): StateType {
    return (state) => ({ ...state, locations })
}

function setLocation(location: Location): StateType {
    return (state) => ({ ...state, currentLocation: location })
}

function setRestrictions(restrictions: Restriction[]): StateType {
    return (state) => ({ ...state, restrictions })
}

export default LocationStore