
import { useEffect, useRef, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';
import { DESDataModel } from '../services/DESDataModel';
import { downloadData } from 'aws-amplify/storage';
import DeviceStore from '../../../Store/DeviceStorage';
import { usePapaParse } from 'react-papaparse';
import './Graphs.css'

function Graphs() {

    const { readString } = usePapaParse();

    const [minHorizontal, setMinHorizontal] = useState<number>(0)
    const [maxHorizontal, setMaxHorizontal] = useState<number>(0)
    const [minVertical, setMinVertical] = useState<number>(0)
    const [maxVertical, setMaxVertical] = useState<number>(0)

    const currendDevice = DeviceStore((state) => state.currentDevice)

    const [kameraRaw, setKameraRaw] = useState<DESDataModel[]>([])
    const [kamera, setKamera] = useState<DESDataModel[]>([])

    const { RangePicker } = DatePicker;
    const [dateFromPickerSetValue, setDateFromPickerSetValue] = useState<number>(-1);
    const [dateToPickerSetValue, setDateToPickerSetValue] = useState<number>(-1);
    const [rangePresetsValue, setRangePresetsValue] = useState<number>(0);


    const [time, setTime] = useState(1);
    useEffect(() => {
        getData()
    }, [time, currendDevice]);

    useEffect(() => {
        setKamera(kameraRaw.filter((data: DESDataModel) => (data.DateTimeOriginalTimeStamp > dateFromPickerSetValue && data.DateTimeOriginalTimeStamp < dateToPickerSetValue)))
    }, [kameraRaw, dateFromPickerSetValue, dateToPickerSetValue]);

    async function getData() {

        try {
            if (currendDevice === null) return
            console.log(currendDevice)
            setRangePresetsValue(currendDevice.rangePreset)
            let dataFormatString = currendDevice.dateFormat

            const downloadResult = await downloadData({
                key: currendDevice.urlData
            }).result;
            const text = await downloadResult.body.text();

            readString(text, {
                header: true,
                worker: true,
                complete: (results) => {
                    const data = (results.data as DESDataModel[]).map(x => {
                        let dmYInt = (Math.round((Number(x.dyMm) + Number.EPSILON) * 10000) / 10000) * -1;
                        x.dyMm = dmYInt;
                        x.DateTimeOriginalTimeStamp = dayjs(x.DateTimeOriginal, dataFormatString).unix()
                        return x;
                    })
                    setKameraRaw(data.filter((x) => x.Time !== ''))
                },
            });
        } catch (error) {
            console.log('Error : ', error);
        }
    }

    useEffect(() => {
        setDateFromPickerSetValue(rangePresets[rangePresetsValue].value[0].startOf('day').unix())
        setDateToPickerSetValue(dayjs().endOf('day').unix())
    }, [rangePresetsValue]);


    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Ostatnie 7 dni', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Ostatnie 30 dni', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: 'Ostatnie 60 dni', value: [dayjs().add(-60, 'd'), dayjs()] },
            { label: 'Cały zakres', value: [dayjs('2022-01-01'), dayjs()] },
        ];

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            const from = dates[0]?.startOf('day').unix();
            const to = dates[1]?.endOf('day').unix();
            if (from && to) {
                setDateFromPickerSetValue(from);
                setDateToPickerSetValue(to);
            }
        } else {
            console.log('Clear');
        }
    };

    useEffect(() => {
        if (kamera.length > 0) {
            setMinHorizontal(kamera[0].dxMm);
            setMaxHorizontal(kamera[0].dxMm);
            setMinVertical(kamera[0].dyMm);
            setMaxVertical(kamera[0].dyMm);

            kamera.forEach((data: DESDataModel) => {
                if (data.dxMm < minHorizontal) {
                    setMinHorizontal(data.dxMm);
                }
                if (data.dxMm > maxHorizontal) {
                    setMaxHorizontal(data.dxMm);
                }
                if (data.dyMm < minVertical) {
                    setMinVertical(data.dyMm);
                }
                if (data.dyMm > maxVertical) {
                    setMaxVertical(data.dyMm);
                }
            });
        }
    }, [kamera]);

    return (
        <div className="GraphsContainer"><div>
            <RangePicker onChange={onRangeChange} presets={rangePresets} defaultValue={[rangePresets[rangePresetsValue].value[0].startOf('day'), dayjs()]} />
        </div>
            {currendDevice && <div>
                <h1>{currendDevice.name}</h1>
                {currendDevice.dxMm &&
                    <>
                        <h2>Kamera przemieszczenie pionowe</h2>
                        <ResponsiveContainer width="95%" height={350}>
                            <LineChart
                                data={kamera}
                                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                            >
                                <XAxis dataKey="DateTimeOriginal" />
                                <YAxis type="number" domain={[Math.round(minVertical) - 1, Math.round(maxVertical) + 1]} allowDataOverflow={false} />
                                <Tooltip />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Line type="monotone" dataKey="dyMm" stroke="#000dff" yAxisId={0} />
                            </LineChart>
                        </ResponsiveContainer>
                    </>
                }
                {currendDevice.dyMm &&
                    <>
                        <h2>Kamera przemieszczenie poziome</h2>
                        <ResponsiveContainer width="95%" height={350}>
                            <LineChart
                                data={kamera}
                                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                            >
                                <XAxis dataKey="DateTimeOriginal" />
                                <YAxis type="number" domain={[Math.round(minHorizontal) - 1, Math.round(maxHorizontal) + 1]} allowDataOverflow={false} />
                                <Tooltip />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Line type="monotone" dataKey="dxMm" stroke="#ff0000" yAxisId={0} />
                            </LineChart>
                        </ResponsiveContainer>
                    </>
                }
            </div>}</div>
    )
}

export default Graphs