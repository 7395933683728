import * as APITypes from "../../API";
import { Client } from "../../models";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const usersClientsByUsersIdWithClients = /* GraphQL */ `query UsersClientsByUsersId(
    $usersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersClientsByUsersId(
      usersId: $usersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        clientId
        createdAt
        updatedAt
        __typename
        client {
            name
            id
            logoUrl
            createdAt
            address
            updatedAt
            nameID
          }
      }
      nextToken
      __typename
    }
  }
  ` as GeneratedQuery<
  APITypes.UsersClientsByUsersIdQueryVariables,
  UsersClientsByUsersIdQueryWithClient
>;

export type UsersClientsByUsersIdQueryWithClient = {
  usersClientsByUsersId?: {
    __typename: "ModelUsersClientConnectionWithClient",
    items: Array<{
      __typename: "UsersClientWithClient",
      id: string,
      usersId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      client: Client,
    } | null>,
    nextToken?: string | null,
  } | null,
};