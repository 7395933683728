import './ObjectStatus.css'
import StatusButton from './StatusButton'
import ObjectStatusStore from '../../Store/ObjectStatus'
import { ObjectStatusEnum } from '../../Store/Enums'

function ObjectStatus() {

    const objectStatus = ObjectStatusStore((state) => state.objectStatus)

    return (
        <div className='ObjectStatusMain'>
            <div className="ObjectStatus">
                {/* <div className="ObjectStatusTitle">
                    <p>Stan obiektu</p>
                </div> */}
                <div className="ObjectStatusValue">
                    <StatusButton className={objectStatus === ObjectStatusEnum.safe ? 'leftButton active' : 'leftButton'} textDisplay='bezpieczny' />
                    <StatusButton className={objectStatus === ObjectStatusEnum.warning ? 'centerButton active' : 'centerButton'} textDisplay='ostrzegawczy' />
                    <StatusButton className={objectStatus === ObjectStatusEnum.alert ? 'rightButton active' : 'rightButton'} textDisplay='alarmowy' />
                </div>
            </div>
        </div>
    )
}

export default ObjectStatus