
import { create } from 'zustand'
import { ObjectStatusEnum } from './Enums'

type State = {
    objectStatus: ObjectStatusEnum
    setStatus: (status: ObjectStatusEnum) => void
}

const ObjectStatusStore = create<State>((set) => ({
    objectStatus: ObjectStatusEnum.none,
    setStatus: (status: ObjectStatusEnum) => set((state) => ({ ...state, objectStatus: status })),
}))

export default ObjectStatusStore


