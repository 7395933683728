
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Device } from '../API'

type StateType = State | Partial<State> | ((state: State) => State | Partial<State>)

type State = {
    devices: Device[]
    currentDevice: Device
}

type Actions = {
    setDevices: (locations: Device[]) => void
    setDevice: (locations: Device) => void
}
const DeviceStore = create<State & Actions, [['zustand/devtools', State & Actions]]>(devtools((set) => ({
    devices: [],
    currentDevice: null,
    setDevices: (devices: Device[]) => set(setDevices(devices), false, "Set devices"),
    setDevice: (device: Device) => set(setDevice(device), false, "Set device")
})))

function setDevices(devices: Device[]): StateType {
    return (state) => ({ ...state, devices })
}

function setDevice(device: Device): StateType {
    return (state) => ({ ...state, currentDevice: device })
}

export default DeviceStore