import LocationView from "../../LocationView/components/LocationView";
import RestrictionView from "./RestrictionView";
import './StatusDashboard.css'

function StatusDashboard() {

    return (
        <div className="StatusDashboard">
            <div className="side">
                <LocationView />
            </div>
            <div className="side">
                <RestrictionView />
            </div>
        </div >
    )
}

export default StatusDashboard;