import { generateClient } from "aws-amplify/api";
import { restrictionsByLocationID } from "../../graphql/queries";
import { Restriction } from '../../API'

const client = generateClient()

export const getRestriction = async (locationID: string) => {
    const allRestrictions = await client.graphql({
        query: restrictionsByLocationID, variables: { locationID }
    });
    return allRestrictions.data.restrictionsByLocationID.items as unknown as Restriction[];
};