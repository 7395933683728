
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { UserModel } from '../Service/User/UserModel'
import { Client, UsersClient } from '../API'

type StateType = State | Partial<State> | ((state: State) => State | Partial<State>)

type State = {
    currentUser: UserModel
    clients: Client[]
    currentClient: Client
    userClients: UsersClient[]
}

type Actions = {
    setUser: (user: UserModel) => void
    setUserClient: (clients: UsersClient[]) => void
    setClients: (clients: Client[]) => void
    setClient: (clients: Client) => void
}
const UserStore = create<State & Actions, [['zustand/devtools', State & Actions]]>(devtools((set) => ({
    currentUser: {
        id: '',
        createdAt: '',
        userName: '',
        userId: '',
        updatedAt: ''
    },
    clients: [],
    userClients: [],
    currentClient: { id: '', createdAt: '', updatedAt: '', usersID: '', __typename: "Client" },
    setUser: (user: UserModel) => set(setUser(user), false, 'Set user'),
    setClients: (clients: Client[]) => set(setClients(clients), false, "Set clients"),
    setClient: (client: Client) => set(setClient(client), false, "Set client"),
    setUserClient: (userClients: UsersClient[]) => set(setUserClient(userClients), false, "Set clients")
})))

function setUser(user: UserModel): StateType {
    return (state) => ({ ...state, currentUser: user })
}

function setClients(clients: Client[]): StateType {
    return (state) => ({ ...state, clients: clients })
}

function setUserClient(userClients: UsersClient[]): StateType {
    return (state) => ({ ...state, userClients: userClients })
}

function setClient(client: Client): StateType {
    return (state) => ({ ...state, currentClient: client })
}

export default UserStore