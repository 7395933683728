import { generateClient } from "aws-amplify/api";
import { locationsByClientID } from "../../graphql/queries";
import { Location } from '../../API'

const client = generateClient()

export const getLocations = async (clientID: string) => {
    const allLocations = await client.graphql({
        query: locationsByClientID, variables: { clientID }
    });
    return allLocations.data.locationsByClientID.items as unknown as Location[];
};
