import { slide as Menu } from 'react-burger-menu'
import './MenuComponent.css'
import { signOut } from 'aws-amplify/auth';
import { LogoutOutlined, HomeOutlined, DatabaseOutlined } from '@ant-design/icons';

function MenuComponent() {

  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (
    <div className='right'>
      <Menu right>
        <div className='menuItem'>
          <HomeOutlined style={{ marginRight: 8 }} />
          <a id="home" href="/">Home</a>
        </div>
        <div className='menuItem'>
          <DatabaseOutlined style={{ marginRight: 8 }} />
          <a id="home" href="/admin/DataManager">Data Manager</a>
        </div>
        <div className='menuItem' onClick={handleSignOut}>
          <LogoutOutlined style={{ marginRight: 8 }} />
          Log out
        </div>
      </Menu>
    </div>
  );
}

export default MenuComponent;