import './LocationView.css'
import DeviceStore from '../../../Store/DeviceStorage'
import LocationStore from '../../../Store/LocationStorage'
import DeviceIcon from './DeviceIcon'
import { Device, DeviceType } from '../../../API'
import { StorageImage } from '@aws-amplify/ui-react-storage';

function LocationView() {

    const devices = DeviceStore((state) => state.devices)
    const currentLocation = LocationStore((state) => state.currentLocation)
    const setDevice = DeviceStore((state) => state.setDevice)
    const firstCameraDevice = devices.filter((device) => device.type === DeviceType.TARGET)[0]
    if (firstCameraDevice)
        setDevice(firstCameraDevice)

    const setDeviceHandler = (device: Device) => {
        if (device.type === DeviceType.TARGET)
            setDevice(device)
    }

    return (
        <div id='container' >
            <StorageImage id='background' imgKey={currentLocation.imageURL} accessLevel={"guest"} alt={''} />
            <div id="infoi">
                {devices.map((device) => {
                    return (
                        <div key={device.id} onClick={() => setDeviceHandler(device)}>
                            <DeviceIcon device={device} />
                        </div>
                    )
                })}
            </div>
        </div >
    )
}

export default LocationView