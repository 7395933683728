import { generateClient } from "aws-amplify/api";
import { devicesByLocationID, deviceDataByDeviceID } from "../../graphql/queries";
import { Device, DeviceData, ModelSortDirection } from '../../API'

const client = generateClient()

export const getDevices = async (locationID: string) => {
    const allDevices = await client.graphql({
        query: devicesByLocationID, variables: { locationID }
    });
    return allDevices.data.devicesByLocationID.items as unknown as Device[];
};

export const getDeviceDataByDeviceID = async (deviceID: string) => {
    const devicesData = await client.graphql({
        query: deviceDataByDeviceID, variables: { deviceID }
    });
    return devicesData.data.deviceDataByDeviceID.items as unknown as DeviceData[];
};