import { listUsers } from "../../graphql/queries";
import { usersClientsByUsersIdWithClients } from "./UserGQLCustome";
import { UserModel } from "./UserModel";
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';

const client = generateClient();

export const getUser = async () => {
    const user = await currentAuthenticatedUser().then(async (user) => {
        const users = await client.graphql({ query: listUsers });

        return users.data.listUsers.items.filter((u) => u.userId === user.userName)[0];
    });
    return {
        userName: user.userName,
        userId: user.userId,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
        id: user.id,
    } as UserModel;
}

export const getClientsByUsersID = async (userID: string) => {
    const clients = await client.graphql({ query: usersClientsByUsersIdWithClients, variables: { usersId: userID } });
    return clients.data.usersClientsByUsersId.items;
}

const currentAuthenticatedUser = async () => {
    try {
        const { username, userId, signInDetails } = await getCurrentUser();
        const user = {
            userName: username,
            userId: userId,
            signInDetails: signInDetails,
        };
        return user;
    } catch (err) {
        console.log(err);
    }
};
