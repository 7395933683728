
export enum ObjectStatusEnum {
    none = 'Brak',
    safe = 'Bezpieczny',
    warning = 'Ostrzegawczy',
    alert = 'Alarmowy'
}

export enum DeviceTypeEnum {
    none = 'none',
    Camera = 'CAMERA',
    Router = 'ROUTER',
    Target = 'TARGET'
}