import UserStore from '../../Store/UserStore';
import './Logo.css'

function Logo() {

    const client = UserStore((state) => state.currentClient)

    return (
        <div className="Logo">
            <img className='IoTConstructionLogo' alt='IoT Construction' src={process.env.PUBLIC_URL + '/logo.png'} />
            {client &&
                <img className='LogoClient' alt='Logo klienta' src={process.env.PUBLIC_URL + '/' + client.nameID.toLowerCase() + '_logo.jpg'} />
            }
        </div>
    )
}

export default Logo