/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getRestriction = /* GraphQL */ `query GetRestriction($id: ID!) {
  getRestriction(id: $id) {
    id
    name
    type
    value
    locationID
    subValue
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRestrictionQueryVariables,
  APITypes.GetRestrictionQuery
>;
export const listRestrictions = /* GraphQL */ `query ListRestrictions(
  $filter: ModelRestrictionFilterInput
  $limit: Int
  $nextToken: String
) {
  listRestrictions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      value
      locationID
      subValue
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRestrictionsQueryVariables,
  APITypes.ListRestrictionsQuery
>;
export const restrictionsByLocationID = /* GraphQL */ `query RestrictionsByLocationID(
  $locationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRestrictionFilterInput
  $limit: Int
  $nextToken: String
) {
  restrictionsByLocationID(
    locationID: $locationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      value
      locationID
      subValue
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RestrictionsByLocationIDQueryVariables,
  APITypes.RestrictionsByLocationIDQuery
>;
export const getUsers = /* GraphQL */ `query GetUsers($id: ID!) {
  getUsers(id: $id) {
    id
    userName
    Clients {
      nextToken
      __typename
    }
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userName
      userId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getDeviceData = /* GraphQL */ `query GetDeviceData($id: ID!) {
  getDeviceData(id: $id) {
    id
    dxMm
    dyMm
    timestamp
    deviceID
    DateTimeOriginal
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceDataQueryVariables,
  APITypes.GetDeviceDataQuery
>;
export const listDeviceData = /* GraphQL */ `query ListDeviceData(
  $filter: ModelDeviceDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeviceData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dxMm
      dyMm
      timestamp
      deviceID
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceDataQueryVariables,
  APITypes.ListDeviceDataQuery
>;
export const deviceDataByDeviceID = /* GraphQL */ `query DeviceDataByDeviceID(
  $deviceID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDeviceDataFilterInput
  $limit: Int
  $nextToken: String
) {
  deviceDataByDeviceID(
    deviceID: $deviceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dxMm
      dyMm
      timestamp
      deviceID
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeviceDataByDeviceIDQueryVariables,
  APITypes.DeviceDataByDeviceIDQuery
>;
export const getDevice = /* GraphQL */ `query GetDevice($id: ID!) {
  getDevice(id: $id) {
    id
    name
    locationID
    DeviceData {
      nextToken
      __typename
    }
    status
    minValue
    maxValue
    xLoc
    yLoc
    dxMm
    dyMm
    urlData
    dateFormat
    prevUrl
    locationName
    type
    rangePreset
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDeviceQueryVariables, APITypes.GetDeviceQuery>;
export const listDevices = /* GraphQL */ `query ListDevices(
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      locationID
      status
      minValue
      maxValue
      xLoc
      yLoc
      dxMm
      dyMm
      urlData
      dateFormat
      prevUrl
      locationName
      type
      rangePreset
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDevicesQueryVariables,
  APITypes.ListDevicesQuery
>;
export const devicesByLocationID = /* GraphQL */ `query DevicesByLocationID(
  $locationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  devicesByLocationID(
    locationID: $locationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      locationID
      status
      minValue
      maxValue
      xLoc
      yLoc
      dxMm
      dyMm
      urlData
      dateFormat
      prevUrl
      locationName
      type
      rangePreset
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DevicesByLocationIDQueryVariables,
  APITypes.DevicesByLocationIDQuery
>;
export const getLocation = /* GraphQL */ `query GetLocation($id: ID!) {
  getLocation(id: $id) {
    id
    name
    clientID
    Devices {
      nextToken
      __typename
    }
    status
    Restrictions {
      nextToken
      __typename
    }
    imageURL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocationQueryVariables,
  APITypes.GetLocationQuery
>;
export const listLocations = /* GraphQL */ `query ListLocations(
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      clientID
      status
      imageURL
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLocationsQueryVariables,
  APITypes.ListLocationsQuery
>;
export const locationsByClientID = /* GraphQL */ `query LocationsByClientID(
  $clientID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  locationsByClientID(
    clientID: $clientID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      clientID
      status
      imageURL
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LocationsByClientIDQueryVariables,
  APITypes.LocationsByClientIDQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    name
    address
    Locations {
      nextToken
      __typename
    }
    nameID
    logoUrl
    userss {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      address
      nameID
      logoUrl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const getUsersClient = /* GraphQL */ `query GetUsersClient($id: ID!) {
  getUsersClient(id: $id) {
    id
    usersId
    clientId
    users {
      id
      userName
      userId
      createdAt
      updatedAt
      __typename
    }
    client {
      id
      name
      address
      nameID
      logoUrl
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsersClientQueryVariables,
  APITypes.GetUsersClientQuery
>;
export const listUsersClients = /* GraphQL */ `query ListUsersClients(
  $filter: ModelUsersClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsersClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      usersId
      clientId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsersClientsQueryVariables,
  APITypes.ListUsersClientsQuery
>;
export const usersClientsByUsersId = /* GraphQL */ `query UsersClientsByUsersId(
  $usersId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUsersClientFilterInput
  $limit: Int
  $nextToken: String
) {
  usersClientsByUsersId(
    usersId: $usersId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      usersId
      clientId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersClientsByUsersIdQueryVariables,
  APITypes.UsersClientsByUsersIdQuery
>;
export const usersClientsByClientId = /* GraphQL */ `query UsersClientsByClientId(
  $clientId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUsersClientFilterInput
  $limit: Int
  $nextToken: String
) {
  usersClientsByClientId(
    clientId: $clientId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      usersId
      clientId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersClientsByClientIdQueryVariables,
  APITypes.UsersClientsByClientIdQuery
>;
