/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateRestrictionInput = {
  id?: string | null,
  name?: string | null,
  type?: string | null,
  value?: string | null,
  locationID: string,
  subValue?: string | null,
};

export type ModelRestrictionConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  value?: ModelStringInput | null,
  locationID?: ModelIDInput | null,
  subValue?: ModelStringInput | null,
  and?: Array< ModelRestrictionConditionInput | null > | null,
  or?: Array< ModelRestrictionConditionInput | null > | null,
  not?: ModelRestrictionConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Restriction = {
  __typename: "Restriction",
  id: string,
  name?: string | null,
  type?: string | null,
  value?: string | null,
  locationID: string,
  subValue?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateRestrictionInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  value?: string | null,
  locationID?: string | null,
  subValue?: string | null,
};

export type DeleteRestrictionInput = {
  id: string,
};

export type CreateUsersInput = {
  id?: string | null,
  userName?: string | null,
  userId?: string | null,
};

export type ModelUsersConditionInput = {
  userName?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  and?: Array< ModelUsersConditionInput | null > | null,
  or?: Array< ModelUsersConditionInput | null > | null,
  not?: ModelUsersConditionInput | null,
};

export type Users = {
  __typename: "Users",
  id: string,
  userName?: string | null,
  Clients?: ModelUsersClientConnection | null,
  userId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUsersClientConnection = {
  __typename: "ModelUsersClientConnection",
  items:  Array<UsersClient | null >,
  nextToken?: string | null,
};

export type UsersClient = {
  __typename: "UsersClient",
  id: string,
  usersId: string,
  clientId: string,
  users: Users,
  client: Client,
  createdAt: string,
  updatedAt: string,
};

export type Client = {
  __typename: "Client",
  id: string,
  name?: string | null,
  address?: string | null,
  Locations?: ModelLocationConnection | null,
  nameID?: string | null,
  logoUrl?: string | null,
  userss?: ModelUsersClientConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  name?: string | null,
  clientID: string,
  Devices?: ModelDeviceConnection | null,
  status?: string | null,
  Restrictions?: ModelRestrictionConnection | null,
  imageURL?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelDeviceConnection = {
  __typename: "ModelDeviceConnection",
  items:  Array<Device | null >,
  nextToken?: string | null,
};

export type Device = {
  __typename: "Device",
  id: string,
  name?: string | null,
  locationID: string,
  DeviceData?: ModelDeviceDataConnection | null,
  status?: boolean | null,
  minValue?: number | null,
  maxValue?: number | null,
  xLoc?: number | null,
  yLoc?: number | null,
  dxMm?: boolean | null,
  dyMm?: boolean | null,
  urlData?: string | null,
  dateFormat?: string | null,
  prevUrl?: string | null,
  locationName?: string | null,
  type?: DeviceType | null,
  rangePreset?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelDeviceDataConnection = {
  __typename: "ModelDeviceDataConnection",
  items:  Array<DeviceData | null >,
  nextToken?: string | null,
};

export type DeviceData = {
  __typename: "DeviceData",
  id: string,
  dxMm?: string | null,
  dyMm?: string | null,
  timestamp?: number | null,
  deviceID: string,
  DateTimeOriginal?: number | null,
  createdAt: string,
  updatedAt: string,
};

export enum DeviceType {
  CAMERA = "CAMERA",
  ROUTER = "ROUTER",
  TARGET = "TARGET",
}


export type ModelRestrictionConnection = {
  __typename: "ModelRestrictionConnection",
  items:  Array<Restriction | null >,
  nextToken?: string | null,
};

export type UpdateUsersInput = {
  id: string,
  userName?: string | null,
  userId?: string | null,
};

export type DeleteUsersInput = {
  id: string,
};

export type CreateDeviceDataInput = {
  id?: string | null,
  dxMm?: string | null,
  dyMm?: string | null,
  timestamp?: number | null,
  deviceID: string,
  DateTimeOriginal?: number | null,
};

export type ModelDeviceDataConditionInput = {
  dxMm?: ModelStringInput | null,
  dyMm?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  deviceID?: ModelIDInput | null,
  DateTimeOriginal?: ModelIntInput | null,
  and?: Array< ModelDeviceDataConditionInput | null > | null,
  or?: Array< ModelDeviceDataConditionInput | null > | null,
  not?: ModelDeviceDataConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateDeviceDataInput = {
  id: string,
  dxMm?: string | null,
  dyMm?: string | null,
  timestamp?: number | null,
  deviceID?: string | null,
  DateTimeOriginal?: number | null,
};

export type DeleteDeviceDataInput = {
  id: string,
};

export type CreateDeviceInput = {
  id?: string | null,
  name?: string | null,
  locationID: string,
  status?: boolean | null,
  minValue?: number | null,
  maxValue?: number | null,
  xLoc?: number | null,
  yLoc?: number | null,
  dxMm?: boolean | null,
  dyMm?: boolean | null,
  urlData?: string | null,
  dateFormat?: string | null,
  prevUrl?: string | null,
  locationName?: string | null,
  type?: DeviceType | null,
  rangePreset?: number | null,
};

export type ModelDeviceConditionInput = {
  name?: ModelStringInput | null,
  locationID?: ModelIDInput | null,
  status?: ModelBooleanInput | null,
  minValue?: ModelFloatInput | null,
  maxValue?: ModelFloatInput | null,
  xLoc?: ModelFloatInput | null,
  yLoc?: ModelFloatInput | null,
  dxMm?: ModelBooleanInput | null,
  dyMm?: ModelBooleanInput | null,
  urlData?: ModelStringInput | null,
  dateFormat?: ModelStringInput | null,
  prevUrl?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  type?: ModelDeviceTypeInput | null,
  rangePreset?: ModelIntInput | null,
  and?: Array< ModelDeviceConditionInput | null > | null,
  or?: Array< ModelDeviceConditionInput | null > | null,
  not?: ModelDeviceConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelDeviceTypeInput = {
  eq?: DeviceType | null,
  ne?: DeviceType | null,
};

export type UpdateDeviceInput = {
  id: string,
  name?: string | null,
  locationID?: string | null,
  status?: boolean | null,
  minValue?: number | null,
  maxValue?: number | null,
  xLoc?: number | null,
  yLoc?: number | null,
  dxMm?: boolean | null,
  dyMm?: boolean | null,
  urlData?: string | null,
  dateFormat?: string | null,
  prevUrl?: string | null,
  locationName?: string | null,
  type?: DeviceType | null,
  rangePreset?: number | null,
};

export type DeleteDeviceInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  name?: string | null,
  clientID: string,
  status?: string | null,
  imageURL?: string | null,
};

export type ModelLocationConditionInput = {
  name?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  imageURL?: ModelStringInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
};

export type UpdateLocationInput = {
  id: string,
  name?: string | null,
  clientID?: string | null,
  status?: string | null,
  imageURL?: string | null,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreateClientInput = {
  id?: string | null,
  name?: string | null,
  address?: string | null,
  nameID?: string | null,
  logoUrl?: string | null,
};

export type ModelClientConditionInput = {
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  nameID?: ModelStringInput | null,
  logoUrl?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
};

export type UpdateClientInput = {
  id: string,
  name?: string | null,
  address?: string | null,
  nameID?: string | null,
  logoUrl?: string | null,
};

export type DeleteClientInput = {
  id: string,
};

export type CreateUsersClientInput = {
  id?: string | null,
  usersId: string,
  clientId: string,
};

export type ModelUsersClientConditionInput = {
  usersId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUsersClientConditionInput | null > | null,
  or?: Array< ModelUsersClientConditionInput | null > | null,
  not?: ModelUsersClientConditionInput | null,
};

export type UpdateUsersClientInput = {
  id: string,
  usersId?: string | null,
  clientId?: string | null,
};

export type DeleteUsersClientInput = {
  id: string,
};

export type ModelRestrictionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  value?: ModelStringInput | null,
  locationID?: ModelIDInput | null,
  subValue?: ModelStringInput | null,
  and?: Array< ModelRestrictionFilterInput | null > | null,
  or?: Array< ModelRestrictionFilterInput | null > | null,
  not?: ModelRestrictionFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUsersFilterInput = {
  id?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  and?: Array< ModelUsersFilterInput | null > | null,
  or?: Array< ModelUsersFilterInput | null > | null,
  not?: ModelUsersFilterInput | null,
};

export type ModelUsersConnection = {
  __typename: "ModelUsersConnection",
  items:  Array<Users | null >,
  nextToken?: string | null,
};

export type ModelDeviceDataFilterInput = {
  id?: ModelIDInput | null,
  dxMm?: ModelStringInput | null,
  dyMm?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  deviceID?: ModelIDInput | null,
  DateTimeOriginal?: ModelIntInput | null,
  and?: Array< ModelDeviceDataFilterInput | null > | null,
  or?: Array< ModelDeviceDataFilterInput | null > | null,
  not?: ModelDeviceDataFilterInput | null,
};

export type ModelDeviceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  locationID?: ModelIDInput | null,
  status?: ModelBooleanInput | null,
  minValue?: ModelFloatInput | null,
  maxValue?: ModelFloatInput | null,
  xLoc?: ModelFloatInput | null,
  yLoc?: ModelFloatInput | null,
  dxMm?: ModelBooleanInput | null,
  dyMm?: ModelBooleanInput | null,
  urlData?: ModelStringInput | null,
  dateFormat?: ModelStringInput | null,
  prevUrl?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  type?: ModelDeviceTypeInput | null,
  rangePreset?: ModelIntInput | null,
  and?: Array< ModelDeviceFilterInput | null > | null,
  or?: Array< ModelDeviceFilterInput | null > | null,
  not?: ModelDeviceFilterInput | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  imageURL?: ModelStringInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  nameID?: ModelStringInput | null,
  logoUrl?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
};

export type ModelUsersClientFilterInput = {
  id?: ModelIDInput | null,
  usersId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUsersClientFilterInput | null > | null,
  or?: Array< ModelUsersClientFilterInput | null > | null,
  not?: ModelUsersClientFilterInput | null,
};

export type ModelSubscriptionRestrictionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  locationID?: ModelSubscriptionIDInput | null,
  subValue?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRestrictionFilterInput | null > | null,
  or?: Array< ModelSubscriptionRestrictionFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUsersFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userName?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUsersFilterInput | null > | null,
  or?: Array< ModelSubscriptionUsersFilterInput | null > | null,
};

export type ModelSubscriptionDeviceDataFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  dxMm?: ModelSubscriptionStringInput | null,
  dyMm?: ModelSubscriptionStringInput | null,
  timestamp?: ModelSubscriptionIntInput | null,
  deviceID?: ModelSubscriptionIDInput | null,
  DateTimeOriginal?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDeviceDataFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeviceDataFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDeviceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  locationID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionBooleanInput | null,
  minValue?: ModelSubscriptionFloatInput | null,
  maxValue?: ModelSubscriptionFloatInput | null,
  xLoc?: ModelSubscriptionFloatInput | null,
  yLoc?: ModelSubscriptionFloatInput | null,
  dxMm?: ModelSubscriptionBooleanInput | null,
  dyMm?: ModelSubscriptionBooleanInput | null,
  urlData?: ModelSubscriptionStringInput | null,
  dateFormat?: ModelSubscriptionStringInput | null,
  prevUrl?: ModelSubscriptionStringInput | null,
  locationName?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  rangePreset?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDeviceFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeviceFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  clientID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  imageURL?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationFilterInput | null > | null,
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  nameID?: ModelSubscriptionStringInput | null,
  logoUrl?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientFilterInput | null > | null,
};

export type ModelSubscriptionUsersClientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  usersId?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUsersClientFilterInput | null > | null,
  or?: Array< ModelSubscriptionUsersClientFilterInput | null > | null,
};

export type CreateRestrictionMutationVariables = {
  input: CreateRestrictionInput,
  condition?: ModelRestrictionConditionInput | null,
};

export type CreateRestrictionMutation = {
  createRestriction?:  {
    __typename: "Restriction",
    id: string,
    name?: string | null,
    type?: string | null,
    value?: string | null,
    locationID: string,
    subValue?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRestrictionMutationVariables = {
  input: UpdateRestrictionInput,
  condition?: ModelRestrictionConditionInput | null,
};

export type UpdateRestrictionMutation = {
  updateRestriction?:  {
    __typename: "Restriction",
    id: string,
    name?: string | null,
    type?: string | null,
    value?: string | null,
    locationID: string,
    subValue?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRestrictionMutationVariables = {
  input: DeleteRestrictionInput,
  condition?: ModelRestrictionConditionInput | null,
};

export type DeleteRestrictionMutation = {
  deleteRestriction?:  {
    __typename: "Restriction",
    id: string,
    name?: string | null,
    type?: string | null,
    value?: string | null,
    locationID: string,
    subValue?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUsersMutationVariables = {
  input: CreateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type CreateUsersMutation = {
  createUsers?:  {
    __typename: "Users",
    id: string,
    userName?: string | null,
    Clients?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    userId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUsersMutationVariables = {
  input: UpdateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type UpdateUsersMutation = {
  updateUsers?:  {
    __typename: "Users",
    id: string,
    userName?: string | null,
    Clients?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    userId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUsersMutationVariables = {
  input: DeleteUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type DeleteUsersMutation = {
  deleteUsers?:  {
    __typename: "Users",
    id: string,
    userName?: string | null,
    Clients?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    userId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceDataMutationVariables = {
  input: CreateDeviceDataInput,
  condition?: ModelDeviceDataConditionInput | null,
};

export type CreateDeviceDataMutation = {
  createDeviceData?:  {
    __typename: "DeviceData",
    id: string,
    dxMm?: string | null,
    dyMm?: string | null,
    timestamp?: number | null,
    deviceID: string,
    DateTimeOriginal?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceDataMutationVariables = {
  input: UpdateDeviceDataInput,
  condition?: ModelDeviceDataConditionInput | null,
};

export type UpdateDeviceDataMutation = {
  updateDeviceData?:  {
    __typename: "DeviceData",
    id: string,
    dxMm?: string | null,
    dyMm?: string | null,
    timestamp?: number | null,
    deviceID: string,
    DateTimeOriginal?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceDataMutationVariables = {
  input: DeleteDeviceDataInput,
  condition?: ModelDeviceDataConditionInput | null,
};

export type DeleteDeviceDataMutation = {
  deleteDeviceData?:  {
    __typename: "DeviceData",
    id: string,
    dxMm?: string | null,
    dyMm?: string | null,
    timestamp?: number | null,
    deviceID: string,
    DateTimeOriginal?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceMutationVariables = {
  input: CreateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type CreateDeviceMutation = {
  createDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    locationID: string,
    DeviceData?:  {
      __typename: "ModelDeviceDataConnection",
      nextToken?: string | null,
    } | null,
    status?: boolean | null,
    minValue?: number | null,
    maxValue?: number | null,
    xLoc?: number | null,
    yLoc?: number | null,
    dxMm?: boolean | null,
    dyMm?: boolean | null,
    urlData?: string | null,
    dateFormat?: string | null,
    prevUrl?: string | null,
    locationName?: string | null,
    type?: DeviceType | null,
    rangePreset?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  input: UpdateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    locationID: string,
    DeviceData?:  {
      __typename: "ModelDeviceDataConnection",
      nextToken?: string | null,
    } | null,
    status?: boolean | null,
    minValue?: number | null,
    maxValue?: number | null,
    xLoc?: number | null,
    yLoc?: number | null,
    dxMm?: boolean | null,
    dyMm?: boolean | null,
    urlData?: string | null,
    dateFormat?: string | null,
    prevUrl?: string | null,
    locationName?: string | null,
    type?: DeviceType | null,
    rangePreset?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  input: DeleteDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type DeleteDeviceMutation = {
  deleteDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    locationID: string,
    DeviceData?:  {
      __typename: "ModelDeviceDataConnection",
      nextToken?: string | null,
    } | null,
    status?: boolean | null,
    minValue?: number | null,
    maxValue?: number | null,
    xLoc?: number | null,
    yLoc?: number | null,
    dxMm?: boolean | null,
    dyMm?: boolean | null,
    urlData?: string | null,
    dateFormat?: string | null,
    prevUrl?: string | null,
    locationName?: string | null,
    type?: DeviceType | null,
    rangePreset?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    clientID: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
    } | null,
    status?: string | null,
    Restrictions?:  {
      __typename: "ModelRestrictionConnection",
      nextToken?: string | null,
    } | null,
    imageURL?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    clientID: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
    } | null,
    status?: string | null,
    Restrictions?:  {
      __typename: "ModelRestrictionConnection",
      nextToken?: string | null,
    } | null,
    imageURL?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    clientID: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
    } | null,
    status?: string | null,
    Restrictions?:  {
      __typename: "ModelRestrictionConnection",
      nextToken?: string | null,
    } | null,
    imageURL?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    nameID?: string | null,
    logoUrl?: string | null,
    userss?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    nameID?: string | null,
    logoUrl?: string | null,
    userss?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    nameID?: string | null,
    logoUrl?: string | null,
    userss?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUsersClientMutationVariables = {
  input: CreateUsersClientInput,
  condition?: ModelUsersClientConditionInput | null,
};

export type CreateUsersClientMutation = {
  createUsersClient?:  {
    __typename: "UsersClient",
    id: string,
    usersId: string,
    clientId: string,
    users:  {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    client:  {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUsersClientMutationVariables = {
  input: UpdateUsersClientInput,
  condition?: ModelUsersClientConditionInput | null,
};

export type UpdateUsersClientMutation = {
  updateUsersClient?:  {
    __typename: "UsersClient",
    id: string,
    usersId: string,
    clientId: string,
    users:  {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    client:  {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUsersClientMutationVariables = {
  input: DeleteUsersClientInput,
  condition?: ModelUsersClientConditionInput | null,
};

export type DeleteUsersClientMutation = {
  deleteUsersClient?:  {
    __typename: "UsersClient",
    id: string,
    usersId: string,
    clientId: string,
    users:  {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    client:  {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetRestrictionQueryVariables = {
  id: string,
};

export type GetRestrictionQuery = {
  getRestriction?:  {
    __typename: "Restriction",
    id: string,
    name?: string | null,
    type?: string | null,
    value?: string | null,
    locationID: string,
    subValue?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRestrictionsQueryVariables = {
  filter?: ModelRestrictionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRestrictionsQuery = {
  listRestrictions?:  {
    __typename: "ModelRestrictionConnection",
    items:  Array< {
      __typename: "Restriction",
      id: string,
      name?: string | null,
      type?: string | null,
      value?: string | null,
      locationID: string,
      subValue?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RestrictionsByLocationIDQueryVariables = {
  locationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRestrictionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RestrictionsByLocationIDQuery = {
  restrictionsByLocationID?:  {
    __typename: "ModelRestrictionConnection",
    items:  Array< {
      __typename: "Restriction",
      id: string,
      name?: string | null,
      type?: string | null,
      value?: string | null,
      locationID: string,
      subValue?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsersQueryVariables = {
  id: string,
};

export type GetUsersQuery = {
  getUsers?:  {
    __typename: "Users",
    id: string,
    userName?: string | null,
    Clients?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    userId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUsersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUsersConnection",
    items:  Array< {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceDataQueryVariables = {
  id: string,
};

export type GetDeviceDataQuery = {
  getDeviceData?:  {
    __typename: "DeviceData",
    id: string,
    dxMm?: string | null,
    dyMm?: string | null,
    timestamp?: number | null,
    deviceID: string,
    DateTimeOriginal?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeviceDataQueryVariables = {
  filter?: ModelDeviceDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeviceDataQuery = {
  listDeviceData?:  {
    __typename: "ModelDeviceDataConnection",
    items:  Array< {
      __typename: "DeviceData",
      id: string,
      dxMm?: string | null,
      dyMm?: string | null,
      timestamp?: number | null,
      deviceID: string,
      DateTimeOriginal?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DeviceDataByDeviceIDQueryVariables = {
  deviceID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeviceDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeviceDataByDeviceIDQuery = {
  deviceDataByDeviceID?:  {
    __typename: "ModelDeviceDataConnection",
    items:  Array< {
      __typename: "DeviceData",
      id: string,
      dxMm?: string | null,
      dyMm?: string | null,
      timestamp?: number | null,
      deviceID: string,
      DateTimeOriginal?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceQueryVariables = {
  id: string,
};

export type GetDeviceQuery = {
  getDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    locationID: string,
    DeviceData?:  {
      __typename: "ModelDeviceDataConnection",
      nextToken?: string | null,
    } | null,
    status?: boolean | null,
    minValue?: number | null,
    maxValue?: number | null,
    xLoc?: number | null,
    yLoc?: number | null,
    dxMm?: boolean | null,
    dyMm?: boolean | null,
    urlData?: string | null,
    dateFormat?: string | null,
    prevUrl?: string | null,
    locationName?: string | null,
    type?: DeviceType | null,
    rangePreset?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDevicesQueryVariables = {
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDevicesQuery = {
  listDevices?:  {
    __typename: "ModelDeviceConnection",
    items:  Array< {
      __typename: "Device",
      id: string,
      name?: string | null,
      locationID: string,
      status?: boolean | null,
      minValue?: number | null,
      maxValue?: number | null,
      xLoc?: number | null,
      yLoc?: number | null,
      dxMm?: boolean | null,
      dyMm?: boolean | null,
      urlData?: string | null,
      dateFormat?: string | null,
      prevUrl?: string | null,
      locationName?: string | null,
      type?: DeviceType | null,
      rangePreset?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DevicesByLocationIDQueryVariables = {
  locationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DevicesByLocationIDQuery = {
  devicesByLocationID?:  {
    __typename: "ModelDeviceConnection",
    items:  Array< {
      __typename: "Device",
      id: string,
      name?: string | null,
      locationID: string,
      status?: boolean | null,
      minValue?: number | null,
      maxValue?: number | null,
      xLoc?: number | null,
      yLoc?: number | null,
      dxMm?: boolean | null,
      dyMm?: boolean | null,
      urlData?: string | null,
      dateFormat?: string | null,
      prevUrl?: string | null,
      locationName?: string | null,
      type?: DeviceType | null,
      rangePreset?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    clientID: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
    } | null,
    status?: string | null,
    Restrictions?:  {
      __typename: "ModelRestrictionConnection",
      nextToken?: string | null,
    } | null,
    imageURL?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      name?: string | null,
      clientID: string,
      status?: string | null,
      imageURL?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LocationsByClientIDQueryVariables = {
  clientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LocationsByClientIDQuery = {
  locationsByClientID?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      name?: string | null,
      clientID: string,
      status?: string | null,
      imageURL?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    nameID?: string | null,
    logoUrl?: string | null,
    userss?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsersClientQueryVariables = {
  id: string,
};

export type GetUsersClientQuery = {
  getUsersClient?:  {
    __typename: "UsersClient",
    id: string,
    usersId: string,
    clientId: string,
    users:  {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    client:  {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersClientsQueryVariables = {
  filter?: ModelUsersClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersClientsQuery = {
  listUsersClients?:  {
    __typename: "ModelUsersClientConnection",
    items:  Array< {
      __typename: "UsersClient",
      id: string,
      usersId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersClientsByUsersIdQueryVariables = {
  usersId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUsersClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersClientsByUsersIdQuery = {
  usersClientsByUsersId?:  {
    __typename: "ModelUsersClientConnection",
    items:  Array< {
      __typename: "UsersClient",
      id: string,
      usersId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersClientsByClientIdQueryVariables = {
  clientId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUsersClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersClientsByClientIdQuery = {
  usersClientsByClientId?:  {
    __typename: "ModelUsersClientConnection",
    items:  Array< {
      __typename: "UsersClient",
      id: string,
      usersId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateRestrictionSubscriptionVariables = {
  filter?: ModelSubscriptionRestrictionFilterInput | null,
};

export type OnCreateRestrictionSubscription = {
  onCreateRestriction?:  {
    __typename: "Restriction",
    id: string,
    name?: string | null,
    type?: string | null,
    value?: string | null,
    locationID: string,
    subValue?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRestrictionSubscriptionVariables = {
  filter?: ModelSubscriptionRestrictionFilterInput | null,
};

export type OnUpdateRestrictionSubscription = {
  onUpdateRestriction?:  {
    __typename: "Restriction",
    id: string,
    name?: string | null,
    type?: string | null,
    value?: string | null,
    locationID: string,
    subValue?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRestrictionSubscriptionVariables = {
  filter?: ModelSubscriptionRestrictionFilterInput | null,
};

export type OnDeleteRestrictionSubscription = {
  onDeleteRestriction?:  {
    __typename: "Restriction",
    id: string,
    name?: string | null,
    type?: string | null,
    value?: string | null,
    locationID: string,
    subValue?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnCreateUsersSubscription = {
  onCreateUsers?:  {
    __typename: "Users",
    id: string,
    userName?: string | null,
    Clients?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    userId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnUpdateUsersSubscription = {
  onUpdateUsers?:  {
    __typename: "Users",
    id: string,
    userName?: string | null,
    Clients?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    userId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnDeleteUsersSubscription = {
  onDeleteUsers?:  {
    __typename: "Users",
    id: string,
    userName?: string | null,
    Clients?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    userId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceDataSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceDataFilterInput | null,
};

export type OnCreateDeviceDataSubscription = {
  onCreateDeviceData?:  {
    __typename: "DeviceData",
    id: string,
    dxMm?: string | null,
    dyMm?: string | null,
    timestamp?: number | null,
    deviceID: string,
    DateTimeOriginal?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceDataSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceDataFilterInput | null,
};

export type OnUpdateDeviceDataSubscription = {
  onUpdateDeviceData?:  {
    __typename: "DeviceData",
    id: string,
    dxMm?: string | null,
    dyMm?: string | null,
    timestamp?: number | null,
    deviceID: string,
    DateTimeOriginal?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceDataSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceDataFilterInput | null,
};

export type OnDeleteDeviceDataSubscription = {
  onDeleteDeviceData?:  {
    __typename: "DeviceData",
    id: string,
    dxMm?: string | null,
    dyMm?: string | null,
    timestamp?: number | null,
    deviceID: string,
    DateTimeOriginal?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceFilterInput | null,
};

export type OnCreateDeviceSubscription = {
  onCreateDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    locationID: string,
    DeviceData?:  {
      __typename: "ModelDeviceDataConnection",
      nextToken?: string | null,
    } | null,
    status?: boolean | null,
    minValue?: number | null,
    maxValue?: number | null,
    xLoc?: number | null,
    yLoc?: number | null,
    dxMm?: boolean | null,
    dyMm?: boolean | null,
    urlData?: string | null,
    dateFormat?: string | null,
    prevUrl?: string | null,
    locationName?: string | null,
    type?: DeviceType | null,
    rangePreset?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceFilterInput | null,
};

export type OnUpdateDeviceSubscription = {
  onUpdateDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    locationID: string,
    DeviceData?:  {
      __typename: "ModelDeviceDataConnection",
      nextToken?: string | null,
    } | null,
    status?: boolean | null,
    minValue?: number | null,
    maxValue?: number | null,
    xLoc?: number | null,
    yLoc?: number | null,
    dxMm?: boolean | null,
    dyMm?: boolean | null,
    urlData?: string | null,
    dateFormat?: string | null,
    prevUrl?: string | null,
    locationName?: string | null,
    type?: DeviceType | null,
    rangePreset?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceFilterInput | null,
};

export type OnDeleteDeviceSubscription = {
  onDeleteDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    locationID: string,
    DeviceData?:  {
      __typename: "ModelDeviceDataConnection",
      nextToken?: string | null,
    } | null,
    status?: boolean | null,
    minValue?: number | null,
    maxValue?: number | null,
    xLoc?: number | null,
    yLoc?: number | null,
    dxMm?: boolean | null,
    dyMm?: boolean | null,
    urlData?: string | null,
    dateFormat?: string | null,
    prevUrl?: string | null,
    locationName?: string | null,
    type?: DeviceType | null,
    rangePreset?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    clientID: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
    } | null,
    status?: string | null,
    Restrictions?:  {
      __typename: "ModelRestrictionConnection",
      nextToken?: string | null,
    } | null,
    imageURL?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    clientID: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
    } | null,
    status?: string | null,
    Restrictions?:  {
      __typename: "ModelRestrictionConnection",
      nextToken?: string | null,
    } | null,
    imageURL?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    clientID: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
    } | null,
    status?: string | null,
    Restrictions?:  {
      __typename: "ModelRestrictionConnection",
      nextToken?: string | null,
    } | null,
    imageURL?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnCreateClientSubscription = {
  onCreateClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    nameID?: string | null,
    logoUrl?: string | null,
    userss?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnUpdateClientSubscription = {
  onUpdateClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    nameID?: string | null,
    logoUrl?: string | null,
    userss?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnDeleteClientSubscription = {
  onDeleteClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    nameID?: string | null,
    logoUrl?: string | null,
    userss?:  {
      __typename: "ModelUsersClientConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUsersClientSubscriptionVariables = {
  filter?: ModelSubscriptionUsersClientFilterInput | null,
};

export type OnCreateUsersClientSubscription = {
  onCreateUsersClient?:  {
    __typename: "UsersClient",
    id: string,
    usersId: string,
    clientId: string,
    users:  {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    client:  {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUsersClientSubscriptionVariables = {
  filter?: ModelSubscriptionUsersClientFilterInput | null,
};

export type OnUpdateUsersClientSubscription = {
  onUpdateUsersClient?:  {
    __typename: "UsersClient",
    id: string,
    usersId: string,
    clientId: string,
    users:  {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    client:  {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUsersClientSubscriptionVariables = {
  filter?: ModelSubscriptionUsersClientFilterInput | null,
};

export type OnDeleteUsersClientSubscription = {
  onDeleteUsersClient?:  {
    __typename: "UsersClient",
    id: string,
    usersId: string,
    clientId: string,
    users:  {
      __typename: "Users",
      id: string,
      userName?: string | null,
      userId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    client:  {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      nameID?: string | null,
      logoUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
