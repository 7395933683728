import LocationStore from '../../../Store/LocationStorage'
import ProhibitionLimitSign from './ProhibitionLimitSign'
import './RestrictionView.css'

function RestrictionView() {

    const restrictions = LocationStore((state) => state.restrictions)

    return (
        <div>
            <div className="RestrictionsText">
                <p>Obowiązujące ograniczenia</p>
            </div>
            <div className="RestrictionsSigns">
                {restrictions.map((restriction) => {
                    return (<ProhibitionLimitSign key={restriction.id} textDisplay={restriction.value} textSubDisplay={restriction.subValue} />)
                })
                }
            </div>
        </div>
    )
}

export default RestrictionView