import StatusDashboard from "../../features/RestrictionsSigns/components/StatusDashboard";
import Graphs from "../../features/Charts/components/Graphs";
import { useParams } from "react-router-dom";
import UserStore from "../../Store/UserStore";
import Footer from "../../features/Footer/components/Footer";
import '../../App.css'
import { getClientsByUsersID, getUser } from "../../Service/User/UserService";
import MenuComponent from "../../components/ManuComponent/MenuComponent";
import { useEffect } from "react";
import { getLocations } from "../../Service/Locations/LocationsService";
import LocationStore from "../../Store/LocationStorage";
import ObjectStatus from "../../features/ObjectStatus/ObjectStatus";
import Logo from "../../features/Logo/LogoComponent";
import LocationDropDown from "../../features/LocationDropDown/LocationDropDown";
import { Client } from "../../API";

function ClientDetails() {
    const params = useParams();
    const currentClient = UserStore((state) => state.currentClient)
    const setClient = UserStore((state) => state.setClient)
    const setUser = UserStore((state) => state.setUser)
    const setClients = UserStore((state) => state.setClients)
    const setLocations = LocationStore((state) => state.setLocations)

    if (currentClient.nameID === undefined) {
        getUser().then((user) => {
            setUser(user)
            if (user === null) return
            getClientsByUsersID(user.id).then((usersclient) => {
                const clients = usersclient.flatMap((uc) => uc.client) as unknown as Client[];
                setClients(clients)
                setClient(clients.find((client) => client.nameID === params.clientID))
            })
        });
    }

    useEffect(() => {
        if (currentClient.nameID !== undefined) {
            getLocations(currentClient.id).then((locations) => {
                setLocations(locations)
            })
        }
    }, [currentClient]);

    return (
        <div>
            <MenuComponent />
            {currentClient.nameID !== undefined &&
                <div className="App">
                    <div>
                        <Logo />
                        <LocationDropDown />
                        <ObjectStatus />
                    </div>
                    <div className="App-container">
                        <StatusDashboard />
                        <Graphs />
                    </div>
                    <Footer />
                </div >
            }
        </div>
    )
}

export default ClientDetails