import Select from 'react-select'
import { useEffect, useState } from "react";
import './LocationDropDown.css'
import ObjectStatusStore from '../../Store/ObjectStatus';
import { ObjectStatusEnum } from '../../Store/Enums';
import { getDevices } from '../../Service/Devices/DevicesService';
import LocationStore from '../../Store/LocationStorage';
import DeviceStore from '../../Store/DeviceStorage';
import { getRestriction } from '../../Service/Restriction/RestrictionService';

type SelectItem = {
    label: string;
    value: string;
}

function LocationDropDown() {

    const locations = LocationStore((state) => state.locations)
    const setLocation = LocationStore((state) => state.setLocation)
    const currentLocation = LocationStore((state) => state.currentLocation)
    const [items, setItems] = useState<SelectItem[]>([])
    const [selectedOption, setSelectedOption] = useState(null);
    const setStatus = ObjectStatusStore((state) => state.setStatus)
    const setDevices = DeviceStore((state) => state.setDevices)
    const setRestrictions = LocationStore((state) => state.setRestrictions)

    useEffect(() => {
        const selectItems = locations.map((client) => {
            return {
                label: client.name,
                value: client.id
            }
        })
        if (selectItems.length > 0) {
            setItems(selectItems)
            setSelectedOption(items[0])
            setLocation(locations[0])
            setStatus(ObjectStatusEnum[locations[0].status])
        }
    }, [locations]);

    useEffect(() => {
        if (items.length > 0) {
            setSelectedOption(items[0])
        }
    }, [items]);

    useEffect(() => {
        if (currentLocation.id === undefined || currentLocation.id === '') return
        getDevices(currentLocation.id).then((devices) => {
            setDevices(devices.sort((a, b) => a.name.localeCompare(b.name)))
        })
        getRestriction(currentLocation.id).then((restriction) => {
            setRestrictions(restriction)
        })
    }, [currentLocation]);

    const handleChange = (value: any) => {
        const location = locations.find((location) => location.id === value.value)
        setLocation(location)
        let enumKey = ObjectStatusEnum[location.status]
        setStatus(enumKey)
    }

    return (
        <div className='LocationDropDown'>
            <Select
                defaultValue={selectedOption}
                onChange={handleChange}
                options={items}
                key={selectedOption}
            />
        </div>
    )
}

export default LocationDropDown 