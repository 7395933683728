import './shadow.css';
import '@aws-amplify/ui-react/styles.css';
import { getClientsByUsersID, getUser } from './Service/User/UserService';
import UserStore from './Store/UserStore';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ClientSelect from './Pages/ClientSelect/ClientSelect';
import ClientDetails from './Pages/ClientDetails/ClientDetails';
import DataManagerPage from './Pages/Admin/DataManager/DataManagerPage';
import { Client } from './API';

function App() {
  const setUser = UserStore((state) => state.setUser)
  const setClients = UserStore((state) => state.setClients)

  useEffect(() => {
    getUser().then((user) => {
      setUser(user)
      if (user === null) return
      getClientsByUsersID(user.id).then(async (userClient) => {
        const clients = userClient.flatMap((uc) => uc.client) as unknown as Client[];
        setClients(clients)
      })
    });
  }, [])

  return (
    <Routes>
      <Route path="/" element={<ClientSelect />} />
      <Route path="/ClientDetails/:clientID" element={<ClientDetails />} />
      <Route path="/admin/DataManager" element={<DataManagerPage />} />
    </Routes>
  )
}

export default withAuthenticator(App);